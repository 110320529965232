export const geomanOptions = {
    position: "topleft",
    drawText: false,
    customControls: true,
    drawRectangle: true,
    drawCircle: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawMarker: false,
    drawPolygon: false,
    cutPolygon: false,
    editMode: true,
    removalMode: true,
    rotateMode: false,
  };
