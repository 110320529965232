import React, { useCallback, useEffect } from "react";
import "./index.scss";
import ProjectSelect from "../../components/ViewSelect/ProjectSelect";
import {
  FeatureGroup,
  ImageOverlay,
  MapContainer,
  Rectangle,
  TileLayer,
} from "react-leaflet";
import { GeomanControls } from "react-leaflet-geoman-v2";
import { geomanOptions } from "../../contexts/OverlayDesigner/constants";
import OverlayPopup from "../../components/OverlayDesigner/OverlayPopup";
import {
  deleteOverlay,
  getOverlays,
  saveOverlay,
  updateOverlay,
} from "../../actions/overlayActions";

const OverlayDesigner = () => {
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [mapRef, setMapRef] = React.useState(null);
  const [controlsCreated, setControlsCreated] = React.useState(false);
  const [overlays, setOverlays] = React.useState([]);

  useEffect(() => {
    if (selectedProject) {
      getOverlays(selectedProject.id).then((response) => {
        const overlays = response.data.map((overlay) => ({
          dbId: overlay.id,
          id: overlay.id,
          coordinates: overlay.coordinates.slice(0, -1).map((c) => ({
            lat: c[1],
            lng: c[0],
          })),
          imageUrl: overlay.overlayImage,
          transparent: false,
          isDirty: false,
        }));
        setOverlays(overlays);
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    if (mapRef && selectedProject) {
      mapRef.target.flyTo(selectedProject.center, 19, { duration: 0.1 });
    }
  }, [selectedProject, mapRef]);

  useEffect(() => {
    if (mapRef) {
      if (controlsCreated) {
        return;
      }

      mapRef.target.on("pm:create", (e) => {
        console.log("pm:create", e);
        setOverlays((prev) => [
          ...prev,
          {
            id: e.layer._leaflet_id,
            coordinates: e.layer.getLatLngs()[0],
            isDirty: true,
          },
        ]);

        e.layer.remove();
      });

      setControlsCreated(true);
    }
  }, [mapRef, controlsCreated]);

  const onOverlaySaved = useCallback(
    async (overlay) => {
      const postingOverlay = {
        coordinates: overlay.coordinates.map((c) => [c.lng, c.lat]),
        imageUrl: overlay.imageUrl,
      };
      postingOverlay.coordinates.push([
        postingOverlay.coordinates[0][0],
        postingOverlay.coordinates[0][1],
      ]);
      if (!overlay.dbId) {
        const dbId = await saveOverlay(selectedProject.id, postingOverlay);
        setOverlays((prev) => {
          const newOverlays = [...prev];
          const index = newOverlays.findIndex((o) => o.id === overlay.id);
          newOverlays[index] = {
            ...newOverlays[index],
            dbId,
            isDirty: false,
          };
          return newOverlays;
        });
      }
    },
    [selectedProject]
  );

  const onOverlayDeleted = useCallback(
    async (overlay) => {
      if (overlay.dbId) {
        // prompt user
        const result = window.confirm("Are you sure you want to delete the overlay?");
        if (result) {
          await deleteOverlay(selectedProject.id, overlay.dbId);
          setOverlays((prev) => prev.filter((o) => o.id !== overlay.id));
        }
      }
    },
    [selectedProject]
  );

  const onOverlayUpdated = useCallback(
    async (overlay) => {
      const postingOverlay = {
        coordinates: overlay.coordinates.map((c) => [c.lng, c.lat]),
        imageUrl: overlay.imageUrl,
        id: overlay.dbId,
      };
      postingOverlay.coordinates.push([
        postingOverlay.coordinates[0][0],
        postingOverlay.coordinates[0][1],
      ]);

      await updateOverlay(selectedProject.id, postingOverlay)
        .then(() => {
          setOverlays((prev) => {
            const newOverlays = [...prev];
            const index = newOverlays.findIndex((o) => o.id === overlay.id);
            newOverlays[index] = {
              ...newOverlays[index],
              isDirty: false,
            };
            return newOverlays;
          });
        })
        .catch(() => {
          alert("Failed to update overlay");
        });
    },
    [selectedProject]
  );

  const onUrlChanged = useCallback((overlay, imageUrl) => {
    setOverlays((prev) => {
      const newOverlays = [...prev];
      const index = newOverlays.findIndex((o) => o.id === overlay.id);
      newOverlays[index] = {
        ...newOverlays[index],
        imageUrl,
        isDirty: true,
      };
      return newOverlays;
    });
  }, []);

  const onTransparencyChanged = useCallback((overlay, transparent) => {
    setOverlays((prev) => {
      const newOverlays = [...prev];
      const index = newOverlays.findIndex((o) => o.id === overlay.id);
      newOverlays[index] = {
        ...newOverlays[index],
        transparent,
      };
      return newOverlays;
    });
  }, []);

  console.log(overlays);

  return (
    <div className="map-container-div-overlays">
      <ProjectSelect
        onProjectSelected={setSelectedProject}
        projectId={selectedProject ? selectedProject.id : null}
        autoselectFunc={(project) => project.name.includes("Redwood")}
      />
      {selectedProject && (
        <MapContainer
          center={selectedProject.center}
          zoom={21}
          scrollWheelZoom={true}
          maxZoom={25}
          whenReady={(m) => setMapRef(m)}
          preferCanvas={true}
        >
          <FeatureGroup>
            <GeomanControls
              options={geomanOptions}
              globalOptions={{
                snappable: true,
              }}
            />
          </FeatureGroup>
          <TileLayer
            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            maxZoom={20}
            maxNativeZoom={19}
          />

          {overlays
            .filter((x) => x.imageUrl)
            .map((overlay) => (
              <ImageOverlay
                key={overlay.id}
                bounds={overlay.coordinates}
                url={overlay.imageUrl}
                opacity={overlay.transparent ? 0.5 : 1}
              ></ImageOverlay>
            ))}

          {overlays.map((overlay) => (
            <Rectangle
              key={overlay.id}
              bounds={overlay.coordinates}
              pathOptions={{
                color: overlay.isDirty ? "yellow" : "blue",
                fillOpacity: 0,
                weight: 2,
              }}
              eventHandlers={{
                "pm:edit": (e) => {
                  console.log("pm:edit", e);
                  setOverlays((prev) =>
                    prev.map((o) => {
                      if (o.id === overlay.id) {
                        return {
                          ...o,
                          coordinates: e.target.getLatLngs()[0],
                          isDirty: true,
                        };
                      }
                      return o;
                    })
                  );
                },
                "pm:remove": (e) => {
                  onOverlayDeleted(overlay);
                },
              }}
            >
              <OverlayPopup
                overlay={overlay}
                onUrlChanged={(url) => onUrlChanged(overlay, url)}
                onSave={() => {
                  if (overlay.dbId) {
                    onOverlayUpdated(overlay);
                  } else {
                    onOverlaySaved(overlay);
                  }
                }}
                onTransparent={(e) =>
                  onTransparencyChanged(overlay, e.target.checked)
                }
              />
            </Rectangle>
          ))}
        </MapContainer>
      )}
    </div>
  );
};

export default OverlayDesigner;
