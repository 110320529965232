import React, { useEffect, useState } from "react";

import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";

const JsonViewModal = ({ visible, onClose, data }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <CModal visible={visible} onClose={onClose} size="xl">
      <CModalHeader>
        <CModalTitle>JSON View</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="json-modal-body">
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Close
        </CButton>
        <CButton
          color={copied ? "success" : "primary"}
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(JSON.stringify(data, null, 2));
          }}
        >
          {copied ? "Copied!" : "Copy"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default JsonViewModal;
