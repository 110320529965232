import React from "react";
import Sidebar from "./Sidebar";

import "./index.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import BlockedStreet from "../blockedStreets";
import CreateBlockedStreet from "../blockedStreets/CreateBlockedStreet";
import Login from "../login/login";
import ProtectedRoute from "./ProtectedRoute";

import { BASE_URL } from "../../config";
import ParkingRules from "../parkingRules";
import CreateParkingRule from "../parkingRules/CreateParkingRule";
import ParkingRuleVehicles from "../parkingRules/ParkingRuleVehicles";
import Stats from "../stats";
import OrsTest from "../orsTest";
import AlertRules from "../alertRules";
import CreateAlertRule from "../alertRules/CreateAlertRule";
import Alerts from "../alerts";
import BlockingGaps from "../blockingGaps";
import CreateBlockingGaps from "../blockingGaps/CreateBlockingGaps";
import Users from "../users";
import CreateUser from "../users/CreateUser";
import SignalRTest from "../signalRTest/signalRTest";
import SensorMock from "../sensorMock";
import SensorMockProvider from "../../contexts/SensorMock/SensorMockContext";
import OverlayDesigner from "../overlayDesigner";
import GroupDesigner from "../groupDesigner";
import ProjectProvider from "../../contexts/ProjectContext/ProjectContext";

const Index = () => {
  return (
    <div className="root-container">
      <BrowserRouter basename={BASE_URL}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <ProtectedRoute>
            {/* <div className="sidebar-container">
                            <Sidebar />
                        </div> */}

            <div className="main-page">
              <Switch>
                {/* <Route path="/blocked-streets/new" component={CreateBlockedStreet} />
                                <Route path="/blocked-streets/:id" component={CreateBlockedStreet} />
                                <Route path="/blocked-streets" component={BlockedStreet} />

                                <Route path="/parking-rules/new" component={CreateParkingRule} />
                                <Route path="/parking-rules/new/:id" component={CreateParkingRule} />
                                <Route path="/parking-rules/from-copy/:id"
                                    render={routeProps => (
                                        <CreateParkingRule {...routeProps} fromCopy={true} />
                                    )} />
                                <Route path="/parking-rules/:id/vehicles" component={ParkingRuleVehicles} />
                                <Route path="/parking-rules/:id" component={CreateParkingRule} />
                                <Route path="/parking-rules" component={ParkingRules} />

                                <Route path="/stats" component={Stats} />

                                <Route path="/ors-test" component={OrsTest} />
                                <Route path="/signalr-test" component={SignalRTest} />

                                <Route path="/alert-rules/new" component={CreateAlertRule} />
                                <Route path="/alert-rules/:id" component={CreateAlertRule} />
                                <Route path="/alert-rules" component={AlertRules} />

                                <Route path="/alerts" component={Alerts} />

                                <Route path="/blocking-gaps/new" component={CreateBlockingGaps} />
                                <Route path="/blocking-gaps/:id" component={CreateBlockingGaps} />
                                <Route path="/blocking-gaps" component={BlockingGaps} />

                                <Route path="/users/new" component={CreateUser} />
                                <Route path="/users/:id" component={CreateUser} />
                                <Route path="/users" component={Users} /> */}

                {/* <Route path="/sensor-mock" component={ */}
                <Route
                  path="/overlay-designer"
                  component={() => <OverlayDesigner />}
                />
                <Route
                  path="/group-designer"
                  component={() => (
                    <ProjectProvider>
                      <GroupDesigner />
                    </ProjectProvider>
                  )}
                />
                <Route
                  path="/"
                  component={() => (
                    <ProjectProvider>
                      <SensorMockProvider>
                        <SensorMock />
                      </SensorMockProvider>
                    </ProjectProvider>
                  )}
                />
              </Switch>
            </div>
          </ProtectedRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Index;
