const json = {

    "host": "0.0.0.0",
    "hostname": "CCS-CA-TOR-SR-004",
    "id": "pt_tor04_3",
    "iteration": "150",
    "unreliable_result": "0",
    "objects": [
        {
            "class": "300",
            "a_lat": "43.6651110",
            "a_lon": "-79.4089039",
            "b_lat": "43.6651046",
            "b_lon": "-79.4089364",
            "c_lat": "43.6650567",
            "c_lon": "-79.4089187",
            "carid": "c61ac639-f472-5ace-ae3d-5f18140ab486",
            "confidence": "100",
            "d_lat": "43.6650631",
            "d_lon": "-79.4088862",
            "hclat": 43.6650839,
            "hclng": -79.4089113,
            "flip": "0",
            "flipelapsed": "5307",
            "groupid": "2",
            "id": "",
            "slotIndex": "500",
            "oid": "c61ac639-f472-5ace-ae3d-5f18140ab486",
            "polygon": [
                {
                    "lat": 43.6651019,
                    "lng": -79.4089021
                },
                {
                    "lat": 43.6650971,
                    "lng": -79.4089266
                },
                {
                    "lat": 43.6650671,
                    "lng": -79.4089155
                },
                {
                    "lat": 43.6650719,
                    "lng": -79.408891
                }
            ],
            "slotid": 102,
            "type": "car",
        }
    ],

    "patches": 0,
    "watcher": "2404201440"
}

export default json;
