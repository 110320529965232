import { LatLng } from "leaflet";
import { getCentroid, getOrderedPoints } from "../../utilities/geoUtilities";

export const actions = {
  SELECTED_PROJECT_CHANGED: "SELECTED_PROJECT_CHANGED",

  FETCH_GAPS: "FETCH_GAPS",
  GAPS_RECEIVED: "GAPS_RECEIVED",
  GAP_SELECTED: "GAP_SELECTED",

  DETECTION_ADDED: "DETECTION_ADDED",
  DETECTION_COORDINATES_UPDATED: "DETECTION_COORDINATES_UPDATED",
  DETECTION_INFO_UPDATED: "DETECTION_INFO_UPDATED",
  DETECTION_DELETED: "DETECTION_DELETED",
  DETECTIONS_CLEARED: "DETECTIONS_CLEARED",
  DETECTION_SELECTED: "DETECTION_SELECTED",

  MAP_MODE_CHANGED: "MAP_MODE_CHANGED",

  FRAME_ADDED: "FRAME_ADDED",
  FRAME_INFO_UPDATED: "FRAME_INFO_UPDATED",
  FRAME_SWITCHED: "FRAME_SWITCHED",

  TIMELINE_IMPORTED: "TIMELINE_IMPORTED",
};

export const mapModes = {
  MOVE: "MOVE",
  ROTATE: "ROTATE",
  DELETE: "DELETE",
  ADD_FREE: "ADD_FREE",
  ADD_CAR: "ADD_CAR",
  ADD_TRUCK: "ADD_TRUCK",
};

export const mapModeToType = {
  [mapModes.ADD_FREE]: "free",
  [mapModes.ADD_CAR]: "car",
  [mapModes.ADD_TRUCK]: "truck",
};

export const transformGaps = (gaps) =>
  gaps
    .map((gap) => ({
      ...gap,
      coordinates: gap.gapGeometry.coordinates[0].map(
        (point) => new LatLng(point[1], point[0])
      ),
    }))
    .map((gap) => ({
      ...gap,
      centroid: getCentroid(gap.coordinates),
    }))
    .map((gap) => ({
      ...gap,
      //coordinates: getOrderedPoints(gap.coordinates, gap.centroid),
    }));

export const geomanOptions = {
  position: "topleft",
  drawText: false,
  customControls: true,
  drawRectangle: false,
  drawCircle: false,
  drawCircleMarker: false,
  drawPolyline: false,
  drawMarker: false,
  drawPolygon: false,
  cutPolygon: false,
  editMode: false,
  removalMode: false,
};

export const vehicleProperties = {
  objectId: "Object ID",
  groupId: "Group ID",
  "boundGap.gapId": "Bound to gap with ID",
};

export const gapProperties = {
  gapId: "Gap ID",
  referenceData: "Ref Data",
  name: "Name",
  gapType: "Gap Type",
};

export const getEndValue = (object, key) => {
  const keys = key.split(".");
  let value = object;

  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]];

    if (!value) {
      return "";
    }
  }

  return value;
};
