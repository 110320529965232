import React, { useMemo } from "react";
import { Polygon } from "react-leaflet";
import {
  useProject,
  useSetSelectedGroup,
} from "../../../contexts/ProjectContext/ProjectContext";
import GroupPopup from "./GroupPopup.jsx";
import "./index.scss";

const Group = ({ group }) => {
  const { selectedGroupId } = useProject();
  const setSelectedGroup = useSetSelectedGroup();

  const isSelected = useMemo(
    () => selectedGroupId === group.groupId,
    [selectedGroupId, group.groupId]
  );

  const color = useMemo(() => (isSelected ? "green" : "grey"), [isSelected]);
  const weight = useMemo(() => (isSelected ? 2 : 1), [isSelected]);
  const fillOpacity = useMemo(() => 0.3, []);

  return (
    <Polygon
      //   pmIgnore={true}
      eventHandlers={{
        click: () => {
          debugger;
          setSelectedGroup(group.groupId);
        },
      }}
      //   allowEditing={false}
      pathOptions={{
        color,
        weight,
        fillOpacity,
      }}
      positions={group.coordinates}
    >
      {isSelected && (
        <GroupPopup group={group} />
      )}
    </Polygon>
  );
};

export default Group;
