import React, { useState, useCallback } from "react";

import { CAlert, CButton, CFormInput } from '@coreui/react';
import { login } from '../../actions/authenticationActions';
import { useHistory } from "react-router-dom";

const Login = () => {

    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [alertVisible, setAlertVisible] = useState(false);

    const onLogin = useCallback(() => {
        login(username, password)
            .then((response) => {
                history.push('/');
            })
            .catch((error) => {
                setAlertVisible(true);
            });
    }, [username, password, history]);

    const onUsernameChanged = useCallback((e) => {
        const value = e.target.value;
        setUsername(value);
    }, []);


    const onPasswordChanged = useCallback((e) => {
        const value = e.target.value;
        setPassword(value);
    }, []);

    return (
        <div className="login-container">
            <div className="login-form">
                <img src="https://images.squarespace-cdn.com/content/v1/5f572e16a570ab2acc63378e/1600869129873-THBBH06Q6JZQ7SGO6JIK/Cleverciti_Logo_sw_gruen.png?format=1500w" alt="Cleverciti Logo" />
                <h1 className="text-center">Login</h1>
                <CFormInput
                    placeholder='Username'
                    type="email"
                    value={username}
                    onChange={onUsernameChanged} />
                <CFormInput
                    placeholder='Password'
                    type="password"
                    value={password}
                    onChange={onPasswordChanged} />
                <CButton type="submit" onClick={onLogin}>Login</CButton>
                <CAlert color="danger" visible={alertVisible}>
                    Wrong username or password!
                </CAlert>
            </div>
        </div>
    );
};

export default Login;
