import { LatLng } from "leaflet";
import { v4 as uuidv4 } from "uuid";

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function toDegrees(radians) {
  return radians * (180 / Math.PI);
}

export function calculateDestinationNoLat(lat1, lng1, distance, bearing) {
  const R = 6371; // Radius of the Earth in kilometers
  const d = distance / R; // Angular distance in radians

  const lat1Rad = toRadians(lat1);
  const lng1Rad = toRadians(lng1);
  const bearingRad = toRadians(bearing);

  const newLatRad = Math.asin(
    Math.sin(lat1Rad) * Math.cos(d) +
      Math.cos(lat1Rad) * Math.sin(d) * Math.cos(bearingRad)
  );

  const newLngRad =
    lng1Rad +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(d) * Math.cos(lat1Rad),
      Math.cos(d) - Math.sin(lat1Rad) * Math.sin(newLatRad)
    );

  const newLat = toDegrees(newLatRad);
  const newLng = toDegrees(newLngRad);

  return new LatLng(newLat, newLng);
}

export function calculateDestination(point, distance, bearing) {
  return calculateDestinationNoLat(point.lat, point.lng, distance, bearing);
}

function angleBetweenPointsNoLat(lat1, lng1, lat2, lng2) {
  const dLng = toRadians(lng2 - lng1);
  lat1 = toRadians(lat1);
  lat2 = toRadians(lat2);

  const y = Math.sin(dLng) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);

  return toDegrees(Math.atan2(y, x));
}

export function angleBetweenPoints(p1, p2) {
  return angleBetweenPointsNoLat(p1.lat, p1.lng, p2.lat, p2.lng);
}

export function getCentroid(pts) {
    var first = pts[0], last = pts[pts.length - 1];
    if (first.lat !== last.lat || first.lng !== last.lng) pts.push(first);

    var twicearea = 0,
        x = 0, y = 0,
        nPts = pts.length,
        p1, p2, f;

    for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = pts[i]; p2 = pts[j];
        f = (p1.lat - first.lat) * (p2.lng - first.lng) - (p2.lat - first.lat) * (p1.lng - first.lng);
        twicearea += f;
        x += (p1.lng + p2.lng - 2 * first.lng) * f;
        y += (p1.lat + p2.lat - 2 * first.lat) * f;
    }

    f = twicearea * 3;
    return { lat: y / f + first.lat, lng: x / f + first.lng };
}

export const vehicleSizesKm = {
  //car: [4.5 / 1000, 2 / 1000],
  car: [2 / 1000, 4.5 / 1000],
  //truck: [10 / 1000, 3 / 1000],
  truck: [3 / 1000, 10 / 1000],
};

export const getVehicle = (id, center, degreesTurned, type = "car") => {
  const a1 = center;
  //const a1 = calculateDestination(center, tolerance, degreesTurned);
  const sizes = vehicleSizesKm[type];
  const a2 = calculateDestination(a1, sizes[0], degreesTurned);
  const a3 = calculateDestination(a2, sizes[1], 0 + degreesTurned + 90);
  const a4 = calculateDestination(a3, sizes[0], 270 + degreesTurned - 90);

  const centroid = getCentroid([a1, a2, a3, a4]);

  const orderedPoints = getOrderedPoints([a1, a2, a3, a4], centroid);

  return {
    id,
    centroid,
    coordinates: orderedPoints,
    objectId: uuidv4(),
  };
};

export function getOrderedPoints(points, centroid) {
  return points;
  const quadrant = [];
  const withoutRingClosed = points.slice(0, points.length - 1);
  withoutRingClosed.forEach(point => {
    if (point.lat >= centroid.lat && point.lng <= centroid.lng) {
      quadrant.push({
        position: 0, // 'NW'
        point
      });
    } else if (point.lat >= centroid.lat && point.lng >= centroid.lng) {
      quadrant.push({
        position: 1, // 'NE'
        point
      });
    } else if (point.lat <= centroid.lat && point.lng >= centroid.lng) {
      quadrant.push({
        position: 2, // 'SE'
        point
      });
    } else {
      quadrant.push({
        position: 3, // 'SW'
        point
      });
    }
  });

  quadrant.sort((a, b) => a.position - b.position);
  return quadrant.map(point => point.point);
}

export function toVeracityCoordinates(coordinates) {
  const retVal = coordinates.map(c => [c.lng, c.lat]);
  retVal.push(retVal[0]);
  return retVal;
}
