import React, { useCallback, useEffect, useMemo } from "react";
import "./index.scss";
import {
  useAddGroup,
  useProject,
  useSetSelectedGroup,
  useSetSelectedProject,
} from "../../contexts/ProjectContext/ProjectContext";
import ProjectSelect from "../../components/ViewSelect/ProjectSelect";
import { FeatureGroup, MapContainer, Pane, Polygon, TileLayer } from "react-leaflet";
import { GeomanControls } from "react-leaflet-geoman-v2";
import Gap from "../../components/SensorMock/Gap";
import Group from "../../components/ProjectComponents/Group";
import SearchSelect from "../../components/base/SearchSelect";
import { CFormCheck } from "@coreui/react";

const geomanOptions = {
  position: "topleft",
  drawText: false,
  customControls: false,
  drawRectangle: false,
  drawCircle: false,
  drawCircleMarker: false,
  drawPolyline: false,
  drawMarker: false,
  drawPolygon: false,
  cutPolygon: false,
  editMode: true,
  removalMode: false,
};

const defaultZoom = 17;

const GroupDesigner = () => {
  const [mapRef, setMapRef] = React.useState(null);
  const [zoomLevel, setZoomLevel] = React.useState(defaultZoom);
  const [showGroups, setShowGroups] = React.useState(false);
  const [showNonClientGroups, setShowNonClientGroups] = React.useState(false);
  const { gaps, groups, selectedProject, selectedGroupId } = useProject();
  const changeProject = useSetSelectedProject();
  const changeGroup = useSetSelectedGroup();
  const addGroup = useAddGroup();

  useEffect(() => {
    if (mapRef && selectedProject) {
      mapRef.target.flyTo(selectedProject.center, zoomLevel, { duration: 0.1 });
    }
  }, [selectedProject, mapRef]);

  useEffect(() => {
    if (mapRef) {

      mapRef.target.off("pm:create");

      mapRef.target.on("zoomend", () => {
        console.log(mapRef.target.getZoom());
        setZoomLevel(mapRef.target.getZoom());
      });

      mapRef.target.on("pm:create", (e) => {
        console.log(e);
        const coordinates = e.layer.getLatLngs()[0];

        addGroup({
          coordinates,
        });
        e.layer.remove();
      });
    }
  }, [mapRef, addGroup]);

  useEffect(() => {
    if (selectedGroupId) {
      setShowGroups(true);
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (!showGroups) {
      changeGroup(null);
    } else {
      setShowNonClientGroups(true);
    }
  }, [showGroups]);

  const visibleGroups = useMemo(() => groups.filter((group) => showNonClientGroups || !group.groupType), [groups, showNonClientGroups]);

  const groupOptions = useMemo(() => visibleGroups
    .filter((group) => group.groupId)
    .map((group) => ({
      label: group.initial.name,
      value: group.groupId,
    })), [visibleGroups]);

  const onGroupSelected = useCallback((e) => {
    changeGroup(e.target.value);
  }, [changeGroup]);

  return (
    <div className="map-container-div-groups">
      <ProjectSelect
        onProjectSelected={(e) => {
          changeProject(e);
          setZoomLevel(defaultZoom);
        }}
        projectId={selectedProject ? selectedProject.id : null}
        autoselectFunc={(project) => project.name.includes("Gelsenkirchen")}
      />

      <div className="group-control-checks">
        <CFormCheck checked={showGroups} label="Show groups" onChange={(e) => setShowGroups(e.target.checked)} />
        <CFormCheck checked={showNonClientGroups} disabled={!showGroups} label="Show non-client groups" onChange={(e) => setShowNonClientGroups(e.target.checked)} />
      </div>

      {showGroups && <SearchSelect
        options={groupOptions}
        onChange={onGroupSelected}
        value={selectedGroupId}
      />}

      {selectedProject && (
        <MapContainer
          center={selectedProject.center}
          zoom={21}
          scrollWheelZoom={true}
          maxZoom={25}
          whenReady={(m) => setMapRef(m)}
          preferCanvas={true}
        >
          <FeatureGroup>
            <GeomanControls
              options={geomanOptions}
              globalOptions={{
                snappable: true,
              }}
            />
          </FeatureGroup>
          <TileLayer
            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            maxZoom={20}
            maxNativeZoom={19}
          />
          <Pane name="gaps" style={{ zIndex: 202 }}>
            {gaps.map((gap, index) => (
              <Gap key={index} gap={gap} fromSensorMock={false} withGroupActions />
            ))}
          </Pane>

          <Pane name="groups" style={{ zIndex: 201 }}>
            {showGroups && visibleGroups.map((group, index) => (
              <Group key={index} group={group} />
            ))}
          </Pane>
        </MapContainer>
      )}
    </div>
  );
};

export default GroupDesigner;
