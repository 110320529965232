import { LatLng } from "leaflet";
import { getCentroid } from "../../utilities/geoUtilities";

export const actions = {
  SELECTED_PROJECT_CHANGED: "SELECTED_PROJECT_CHANGED",

  FETCH_GAPS: "FETCH_GAPS",
  GAPS_RECEIVED: "GAPS_RECEIVED",
  GAP_SELECTED: "GAP_SELECTED",

  FETCH_GROUPS: "FETCH_GROUPS",
  GROUPS_RECEIVED: "GROUPS_RECEIVED",
  GROUP_SELECTED: "GROUP_SELECTED",
  GROUP_ADDED: "GROUP_ADDED",

  SAVE_GROUP: "SAVE_GROUP",
  GROUP_SAVED: "GROUP_SAVED",

  DELETE_GROUP: "DELETE_GROUP",
  GROUP_DELETED: "GROUP_DELETED",
};

export const transformGaps = (gaps) =>
  gaps
    .map((gap) => ({
      ...gap,
      coordinates: gap.gapGeometry.coordinates[0].map(
        (point) => new LatLng(point[1], point[0])
      ),
    }))
    .map((gap) => ({
      ...gap,
      centroid: getCentroid(gap.coordinates),
    }))
    .map((gap) => ({
      ...gap,
      //coordinates: getOrderedPoints(gap.coordinates, gap.centroid),
    }));

export const transformGroups = (groups) =>
  groups
    .map((group) => ({
      ...group,
      coordinates: group.coordinates.map(
        (point) => new LatLng(point[1], point[0])
      ),
    }))
    .map((group) => ({
      ...group,
      centroid: getCentroid(group.coordinates),
    }))
    .map((group) => ({
      ...group,
      initial: { ...group },
    }));

export const isEntityDirty = (entity) => {
  return !entity.initial || Object.keys(entity)
    .filter((key) => !["initial"].includes(key))
    .some(
      (key) => entity[key] !== entity.initial[key]
    );
}
