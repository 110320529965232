import axios from "axios";

export const getGroups = (projectId) =>
    axios.get(`/projects/${projectId}/groups`);

export const createGroup = (projectId, group) =>
    axios.post(`/projects/${projectId}/groups`, group)
        .then(response => response.data);

export const deleteGroup = (projectId, groupId) =>
    axios.delete(`/projects/${projectId}/groups/${groupId}`);

export const updateGroup = (projectId, group) =>
    axios.put(`/projects/${projectId}/groups/${group.groupId}`, group);

export const removeGapFromGroup = (projectId, groupId, gapId) =>
    axios.delete(`/projects/${projectId}/groups/${groupId}/gaps?ids=${gapId}`);

export const addGapToGroup = (projectId, groupId, gapId) =>
    axios.post(`/projects/${projectId}/groups/${groupId}/gaps?ids=${gapId}`);
