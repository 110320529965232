import React, { useCallback } from "react";
import { Popup } from "react-leaflet";
import { useFetchGaps, useProject, useSetSelectedGroup } from "../../../contexts/ProjectContext/ProjectContext";
import "./index.scss";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilLocationPin } from "@coreui/icons";
import { addGapToGroup, removeGapFromGroup } from "../../../actions/groupActions";
import { CButton } from "@coreui/react";

const GapGroupPopup = ({
    gap,
}) => {
    const { groups, selectedProject, selectedGroupId } = useProject();
    const selectGroup = useSetSelectedGroup();
    const fetchGaps = useFetchGaps();

    const partOfGroups = groups.filter(x => gap.groupIds.includes(x.groupId));

    const onGroupRemoved = useCallback((groupId) => {
        removeGapFromGroup(selectedProject.id, groupId, gap.gapId)
            .then(() => {
                fetchGaps();
            });
    }, [selectedProject, gap, fetchGaps]);

    const onGroupAdded = useCallback(() => {
        addGapToGroup(selectedProject.id, selectedGroupId, gap.gapId)
            .then(() => {
                fetchGaps();
            });
    }, [selectedProject, selectedGroupId, gap, fetchGaps]);

    return <Popup className="gap-group-popup">
        <div className="gap-group-container">
            <div className="gap-name">{gap.name}</div>
            {partOfGroups.map(x =>
                <div key={x.groupId} className="gap-group-entry">
                    {x.initial.name}
                    <div className="gap-group-entry-actions">
                        <CIcon icon={cilLocationPin} className="text-primary" onClick={() => selectGroup(x.groupId)} />
                        <CIcon icon={cilTrash} className="text-danger" onClick={() => onGroupRemoved(x.groupId)} />
                    </div>
                </div>)
            }

            <CButton color="primary" disabled={!selectedGroupId} onClick={() => onGroupAdded()}>Add to selected group</CButton>
        </div>
    </Popup>
}

export default GapGroupPopup;
