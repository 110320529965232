import React, { useMemo } from "react";
import { Polygon } from "react-leaflet";
import InfoPopup from "./InfoPopup";
import {
  useProject,
  useSetSelectedGap,
} from "../../contexts/ProjectContext/ProjectContext";
import { CButton } from "@coreui/react";
import GapGroupPopup from "../ProjectComponents/Group/GapGroupPopup";

const Gap = ({ gap, fromSensorMock = true, withGroupActions = false }) => {
  const { selectedGapId, selectedBoundGapId, selectedGroupId } = useProject();
  const setSelectedGap = useSetSelectedGap();

  const isBoundSelected = useMemo(
    () => selectedBoundGapId === gap.gapId,
    [selectedBoundGapId, gap.gapId]
  );

  const isInGroup = useMemo(
    () => gap.groupIds.includes(selectedGroupId),
    [selectedGroupId, gap.groupIds]
  );

  const fillColor = useMemo(
    () => (isBoundSelected || isInGroup ? "orange" : "grey"),
    [isBoundSelected, isInGroup]
  );

  const isSelected = useMemo(
    () => selectedGapId === gap.gapId,
    [selectedGapId, gap.gapId]
  );

  const color = useMemo(() => (isSelected ? "black" : "grey"), [isSelected]);
  const weight = useMemo(() => (isSelected ? 2 : 1), [isSelected]);
  const fillOpacity = useMemo(() => (isSelected ? 0.7 : 0.5), [isSelected]);

  return (
    <Polygon
      pmIgnore={true}
      eventHandlers={{
        click: () => setSelectedGap(gap.gapId),
      }}
      allowEditing={false}
      pathOptions={{
        color,
        weight,
        fillOpacity,
        fillColor,
      }}
      positions={gap.coordinates}
    >
      {fromSensorMock && <InfoPopup object={gap} isGap />}
      {withGroupActions && <GapGroupPopup gap={gap} />}
    </Polygon>
  );
};

export default Gap;
