import { CButton, CFormInput } from "@coreui/react";
import React from "react";
import { resetState, sendFrame } from "../../actions/sensorActions";
import generateJson from "../../utilities/sensorUtilities";
import ProjectSelect from "../ViewSelect/ProjectSelect";
import {
  useSensorMock,
  useUpdateFrameInfo,
} from "../../contexts/SensorMock/SensorMockContext";
import JsonViewModal from "./JsonViewModal";
import {
  useProject,
  useSetSelectedProject,
} from "../../contexts/ProjectContext/ProjectContext";

const FrameControls = () => {
  const [viewJson, setViewJson] = React.useState(false);
  const { defaultProjectName, currentFrame, detections } = useSensorMock();
  const { selectedProject } = useProject();
  const updateFrameInfo = useUpdateFrameInfo();
  const changeProject = useSetSelectedProject();

  return (
    <div>
      <div className="controls">
        <ProjectSelect
          onProjectSelected={changeProject}
          projectId={selectedProject ? selectedProject.id : null}
          autoselectFunc={(project) =>
            project.name.includes(defaultProjectName)
          }
        />

        <CFormInput
          type="text"
          value={currentFrame.frame}
          placeholder="Frame name"
          onChange={(e) => updateFrameInfo("frame", e.target.value)}
        />
        <CFormInput
          type="text"
          value={currentFrame.sensorName}
          placeholder="Sensor name"
          onChange={(e) => updateFrameInfo("sensorName", e.target.value)}
        />
      </div>
      <div className="controls-buttons">
        <CButton onClick={() => setViewJson(true)}>View JSON</CButton>
        <CButton
          onClick={() => {
            const json = generateJson(
              detections,
              currentFrame.frame,
              currentFrame.sensorName
            );
            console.log(json);
            sendFrame(currentFrame.frame, json).then((response) => {
              console.log(response);
            });
          }}
          disabled={
            !currentFrame.frame ||
            !currentFrame.sensorName ||
            !detections.length
          }
        >
          Send
        </CButton>
        <CButton onClick={() => resetState(selectedProject.id)}>
          Reset Database (UNSAFE!)
        </CButton>
        <JsonViewModal
          visible={viewJson}
          onClose={() => setViewJson(false)}
          data={generateJson(
            detections,
            currentFrame.frame,
            currentFrame.sensorName
          )}
        />
      </div>
    </div>
  );
};

export default FrameControls;
