import axios from "axios";
import generateJson from "../utilities/sensorUtilities";

export const sendFrame = (frame, json) => {
  return axios.post(`/sensorsink/legacy/v1_5`, {
    frame,
    full: JSON.stringify(json),
  });
};

export const postTimeline = (timeline) =>
  axios.post(
    `/sensorsink/timeline`,
    timeline.map((t) => ({
      dateTime: t.datetime,
      frame: {
        frame: t.frame,
        full: JSON.stringify(generateJson(t.detections, t.frame, t.sensorName)),
      },
    }))
  );

export const resetState = projectId => axios.delete(`/projects/${projectId}/state`);
