import React, { useCallback, useEffect, useMemo } from "react";
import { CFormInput } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilDelete } from "@coreui/icons";
import "./index.scss";
import { orderBy } from "lodash";

const SearchSelect = ({ onChange, options, ...props }) => {
  const [textValue, setTextValue] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [listOpen, setListOpen] = React.useState(false);
  const [highlightedIndex, setHighlightedIndex] = React.useState(0);
  const textRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  useEffect(() => {
    if (props.value) {
      const option = options.find((option) => option.value === props.value);
      if (option) {
        setTextValue(option.label);
      } else {
        setTextValue("");
      }
    } else {
      setTextValue("");
    }
  }, [props.value, options]);

  const fireChange = useCallback((option) => {
      onChange({
        target: {
          value: option.value,
        },
      });
  }, [onChange]);

  useEffect(() => {
    if (!listOpen) {
      return;
    }

    const textRect = textRef.current.getBoundingClientRect();
    const tolerance = 15;
    dropdownRef.current.style.left = textRect.left - tolerance + "px";
    dropdownRef.current.style.top =
      textRect.top + textRect.height - tolerance + "px";
    dropdownRef.current.style.width = textRef.current.offsetWidth + "px";
  }, [listOpen]);

  const filteredOptions = useMemo(() => {
    const result = options.filter((option) =>
      option.label.toLowerCase().includes(textValue.toLowerCase())
    );
    return orderBy(result, ["label"], ["asc"]);
  }, [options, textValue]);

  return (
    <div className="search-select-container">
      <CFormInput
        onFocus={() => {
          setListOpen(true);
          setIsFocused(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setListOpen(false);
            setHighlightedIndex(0);
          }, 100);
          setIsFocused(false);
        }}
        ref={textRef}
        list="cityname"
        {...props}
        value={textValue}
        onChange={(e) => {
          setHighlightedIndex(0);
          setListOpen(true);
          setTextValue(e.target.value);
        }}
        onClick={() => {
          if (textValue === "") {
            setListOpen(true);
          }
        }}
        onKeyDown={(e) => {
          if (!isFocused) {
            return;
          }

          if (e.key === "ArrowDown") {
            if (!listOpen) setListOpen(true);
            else {
              setHighlightedIndex((prev) =>
                prev < filteredOptions.length - 1 ? prev + 1 : prev
              );
            }
          } else if (e.key === "ArrowUp") {
            setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : prev));
          } else if (e.key === "Enter") {
            setTextValue(filteredOptions[highlightedIndex].label);
            setListOpen(false);
            fireChange(filteredOptions[highlightedIndex]);
            setHighlightedIndex(0);
          } else if (e.key === "Escape") {
            setListOpen(false);
            setHighlightedIndex(0);
          }
        }}
      />
      {listOpen && (
        <div className="search-list" ref={dropdownRef}>
          <ul>
            {filteredOptions.map((option, ix) => (
              <li
                key={ix}
                onClick={(e) => {
                  setTextValue(option.label);
                  setListOpen(false);
                  fireChange(option);
                }}
                onMouseEnter={() => {
                  setHighlightedIndex(option.value);
                }}
                className={highlightedIndex === ix ? "highlighted" : ""}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
      <CIcon
        icon={cilDelete}
        className="text-danger clear-icon"
        onClick={() => {
          setTextValue("");
          textRef.current.focus();
        }}
      />
    </div>
  );
};

export default SearchSelect;
