import React, { useEffect, useState, useMemo } from 'react';
import { getAllProjects } from '../../actions/projects';
import SearchSelect from '../base/SearchSelect';

const ProjectSelect = ({
    onProjectSelected,
    autoselectFunc,
    projectId
}) => {
    const [availableProjects, setAvailableProjects] = useState([]);

    useEffect(() => {
        getAllProjects()
            .then((projects) => {
                setAvailableProjects(projects.data);
                if (autoselectFunc) {
                    const selectedProject = projects.data.find(autoselectFunc);
                    if (selectedProject) {
                        onProjectSelected(selectedProject);
                    } else if (projects.data.length > 0) {
                        onProjectSelected(projects.data[0]);
                    }
                } else if (projects.data.length > 0) {
                    onProjectSelected(projects.data[0]);
                }
            });
    }, []);

    const dropdownData = useMemo(() => availableProjects.map((project) => ({
        label: project.name,
        value: project.id
    })), [availableProjects]);

    return (
        <div>
            <h3>
                <SearchSelect
                    options={dropdownData}
                    onChange={(e) => {
                        const selectedProject = availableProjects.find((project) => project.id === e.target.value);
                        onProjectSelected(selectedProject);
                    }}
                    value={projectId}
                />
            </h3>
        </div>
    );
}

export default ProjectSelect;
