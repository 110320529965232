import React, { useMemo } from "react";
import { Polygon } from "react-leaflet";
import InfoPopup from "./InfoPopup";
import { useAddDetection, useDeleteDetection, useSensorMock, useSetSelectedDetection, useUpdateDetectionCoordinates } from "../../contexts/SensorMock/SensorMockContext";
import { mapModeToType, mapModes } from "../../contexts/SensorMock/constants";

const Detection = ({ detection }) => {
  const { selectedDetectionId, mapMode } = useSensorMock();
  const updateDetectionCoordinates = useUpdateDetectionCoordinates();
  const addDetection = useAddDetection();
  const deleteDetection = useDeleteDetection();
  const selectDetection = useSetSelectedDetection();
  const isSelected = useMemo(() => detection.id === selectedDetectionId, [
    detection.id,
    selectedDetectionId,
  ]);

  const color = useMemo(() => {
    if (isSelected) {
      return "black";
    }
    if (detection.occupied) {
      return "red";
    }
    return "green";
  }, [isSelected, detection.occupied]);
  const weight = useMemo(() => (isSelected ? 2 : 1), [isSelected]);
  const fillOpacity = useMemo(() => (isSelected ? 0.7 : 0.5), [isSelected]);
  const fillColor = useMemo(
    () => (detection.occupied ? "red" : "green"),
    [detection.occupied]
  );

  return (
    <Polygon
      id={detection.id}
      eventHandlers={{
        "pm:edit": (e) => updateDetectionCoordinates(detection.id, e.target.getLatLngs()[0]),
        click: (e) => {
          const type = mapModeToType[mapMode];
          if (mapMode === mapModes.DELETE) {
            deleteDetection(detection.id);
          } else if (type) {
            addDetection(e.latlng, type, detection.id);
          } else {
            if (isSelected) {
              return;
            } else {
              selectDetection(detection.id);
            }
          }
        }
      }}
      pathOptions={{
        color,
        weight,
        fillOpacity,
        fillColor,
      }}
      positions={detection.coordinates}
    >
      <InfoPopup object={detection} />
    </Polygon>
  );
};

export default Detection;
