import { CButton, CFormInput } from "@coreui/react";
import React, { useMemo } from "react";
import { Popup } from "react-leaflet";
import { useDeleteGroup, useSaveGroup, useUpdateGroup } from "../../../contexts/ProjectContext/ProjectContext";
import { isEntityDirty } from "../../../contexts/ProjectContext/constants";

const properties = {
  groupId: "Group ID",
  name: "Name",
};

const disabledProperties = {
  groupId: true,
};

const GroupPopup = ({ group }) => {
  const updateGroup = useUpdateGroup();
  const saveGroup = useSaveGroup();
  const deleteGroup = useDeleteGroup();
  const isDirty = useMemo(() => isEntityDirty(group), [group]);

  const saveButtonText = useMemo(() => group.groupId ? "Save" + (isDirty ? "*" : "") : "Create", [group, isDirty]);


  return (
    <Popup className="group-info-popup">
      <div className="group-info-container">
        {Object.keys(properties).map((key) => (
          <CFormInput
            key={key}
            // disabled={disabledProperties[key]}
            disabled
            label={properties[key]}
            name={key}
            value={group[key]}
            onChange={(e) =>
              updateGroup({
                ...group,
                [key]: e.target.value,
              })
            }
          />
        ))}

        {/* <CButton onClick={() => saveGroup(group)}>{saveButtonText}</CButton>
        <CButton onClick={() => deleteGroup(group.groupId)} disabled={!group.groupId}>Delete</CButton>
        <CButton>Include colliding gaps</CButton> */}
      </div>
    </Popup>
  );
};

export default GroupPopup;
