export const googleMapsPointToCoordinates = (link) => {
  const linkSplit = link.split("/");
  let latLongPart = linkSplit.find((part) => part.includes("@" || ","));
  if (latLongPart) {
    latLongPart = latLongPart.replace("@", "");
    const lat = latLongPart.split(",")[0];
    const lng = latLongPart.split(",")[1];
    return { latitude: lat, longitude: lng };
  }
  return null;
};

export const keyboardActions = (e) => {
  if (e.repeat) {
    return;
  }
  document.removeEventListener("keydown", this);
  switch (e.key) {
    case "r":
      document.getElementsByClassName("leaflet-pm-icon-rotate")[0].click();
      break;
    case "m":
      document.getElementsByClassName("leaflet-pm-icon-drag")[0].click();
      break;
    case "f":
      document.getElementsByClassName("add-free-button")[0].click();
      break;
    case "c":
      document.getElementsByClassName("add-car-button")[0].click();
      break;
    case "t":
      document.getElementsByClassName("add-truck-button")[0].click();
      break;
    case "d":
      document.getElementsByClassName("delete-button")[0].click();
      break;
    default:
      break;
  }
};
