import axios from "axios";

export const login = (username, password) => {
    return axios.post("/users/login", {
        email: username,
        password
    }).then((response) => {
        if (response.data.token) {
            localStorage.setItem("token", response.data.token);
        } else {
            throw new Error("Invalid username or password");
        }
    });
};

export const logout = () => {
    return axios.post("/users/logout").then(() => {
        localStorage.removeItem("token");
        window.location.reload();
    });
};

export const getAllUsersForProjectId = (projectId) => {
    return axios.get(`/projects/${projectId}/users`);
}
