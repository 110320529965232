import axios from "axios";

export const getOverlays = (projectId) =>
    axios.get(`/projects/${projectId}/overlays`);

export const saveOverlay = (projectId, overlay) =>
    axios.post(`/projects/${projectId}/overlays`, overlay)
        .then(response => response.data);

export const deleteOverlay = (projectId, overlayId) =>
    axios.delete(`/projects/${projectId}/overlays/${overlayId}`);

export const updateOverlay = (projectId, overlay) =>
    axios.put(`/projects/${projectId}/overlays/${overlay.id}`, overlay);
