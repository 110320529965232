import axios from "axios";

export const getFiveMinuteStats = (viewIds, groupName, date, totalHours) => {
  if (groupName) {
    return axios.get(
      `views/${viewIds}/group/${groupName}/statistics`,
      {
        params: { date, totalHours },
      }
    );
  } else {
    return axios.get(`views/${viewIds}/statistics`, {
      params: { date, totalHours },
    });
  }
};
