import json from './sensorJson';

const generateJson = (vehicles, frame, sensorName) => {
    const firstObject = json.objects[0];
    const objects = vehicles.map((vehicle, ix) => {
        return {
            ...firstObject,
            class: "300",
            a_lat: vehicle.coordinates[0].lat,
            a_lon: vehicle.coordinates[0].lng,
            b_lat: vehicle.coordinates[1].lat,
            b_lon: vehicle.coordinates[1].lng,
            c_lat: vehicle.coordinates[2].lat,
            c_lon: vehicle.coordinates[2].lng,
            d_lat: vehicle.coordinates[3].lat,
            d_lon: vehicle.coordinates[3].lng,
            hclat: vehicle.coordinates[0].lat,
            hclng: vehicle.coordinates[0].lng,
            carid: vehicle.carid,
            groupid: parseInt(vehicle.groupId),
            oid: vehicle.objectId,
            polygon: vehicle.coordinates.map((latlng) => ({
                lat: latlng.lat,
                lng: latlng.lng
            })),
            slotid: vehicle.slotid,
            type: vehicle.occupied ? "car" : "free",
        }
    });

    return {
        ...json,
        id: frame,
        hostname: sensorName,
        objects,
    };
}

export default generateJson;
