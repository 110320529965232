import './App.css';
import Index from './pages/index';

function App() {
  return (
    <Index />
  );
}

export default App;
