import React, { useEffect } from "react";
import { FeatureGroup, MapContainer, Polyline, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-path-drag";
import "./index.scss";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { GeomanControls } from "react-leaflet-geoman-v2";
import Gap from "../../components/SensorMock/Gap";
import Detection from "../../components/SensorMock/Detection";
import { keyboardActions } from "../../utilities/googleMapsUtilities";
import Timeline from "../../components/SensorMock/Timeline";
import {
  useAddDetection,
  useClearDetections,
  useSensorMock,
  useSetMapMode,
  useSetSelectedDetection,
} from "../../contexts/SensorMock/SensorMockContext";
import {
  geomanOptions,
  mapModeToType,
  mapModes,
} from "../../contexts/SensorMock/constants";
import FrameControls from "../../components/SensorMock/FrameControls";
import {
  useProject,
  useSetSelectedGap,
} from "../../contexts/ProjectContext/ProjectContext";

const SensorMock = () => {
  const [mapRef, setMapRef] = React.useState(null);
  const [controlsCreated, setControlsCreated] = React.useState(false);

  const [clickedEvent, setClickedEvent] = React.useState(false);

  const { detections, mapMode } = useSensorMock();
  const { gaps, selectedProject } = useProject();
  const setSelectedGap = useSetSelectedGap();
  const setSelectedDetection = useSetSelectedDetection();
  const addDetection = useAddDetection();
  const clearDetections = useClearDetections();
  const setMapMode = useSetMapMode();

  useEffect(() => {
    document.addEventListener("keydown", keyboardActions);
    document.addEventListener("keyup", keyboardActions);

    return () => {
      document.removeEventListener("keydown", keyboardActions);
      document.removeEventListener("keyup", keyboardActions);
    };
  }, []);

  useEffect(() => {
    if (mapRef && selectedProject) {
      mapRef.target.flyTo(selectedProject.center, 19, { duration: 0.1 });
    }
  }, [selectedProject, mapRef]);

  useEffect(() => {
    if (clickedEvent) {
      const type = mapModeToType[mapMode];
      if (type) {
        addDetection(clickedEvent.latlng, type);
      } else {
        setSelectedGap(null);
        setSelectedDetection(null);
      }
      setClickedEvent(null);
    }
  }, [
    clickedEvent,
    mapMode,
    addDetection,
    setSelectedDetection,
    setSelectedGap,
  ]);

  useEffect(() => {
    if (mapRef) {
      if (controlsCreated) {
        return;
      }

      mapRef.target.pm.Toolbar.createCustomControl({
        name: "Delete",
        block: "custom",
        className: "delete-button",
        onClick: (e) => setMapMode(mapModes.DELETE),
      });

      mapRef.target.pm.Toolbar.createCustomControl({
        name: "Add free space",
        toggle: true,
        block: "custom",
        className: "add-free-button",
        onClick: (e) => setMapMode(mapModes.ADD_FREE),
      });

      mapRef.target.pm.Toolbar.createCustomControl({
        name: "Add car",
        toggle: true,
        block: "custom",
        className: "add-car-button",
        onClick: (e) => setMapMode(mapModes.ADD_CAR),
      });

      mapRef.target.pm.Toolbar.createCustomControl({
        name: "Add truck",
        toggle: true,
        block: "custom",
        className: "add-truck-button",
        onClick: (e) => setMapMode(mapModes.ADD_TRUCK),
      });

      mapRef.target.pm.Toolbar.createCustomControl({
        name: "Clear all",
        block: "custom",
        toggle: false,
        className: "clear-button",
        onClick: () => clearDetections(),
      });

      mapRef.target.on("click", (e) => {
        setClickedEvent(e);
      });

      setControlsCreated(true);
    }
  }, [mapRef, controlsCreated, clearDetections, setMapMode]);

  return (
    <div className="map-container-div">
      <Timeline />
      <FrameControls />

      {selectedProject && (
        <MapContainer
          center={selectedProject.center}
          zoom={21}
          scrollWheelZoom={true}
          maxZoom={25}
          whenReady={(m) => setMapRef(m)}
          preferCanvas={true}
        >
          <FeatureGroup>
            <GeomanControls
              options={geomanOptions}
              globalOptions={{
                snappable: true,
              }}
            />
          </FeatureGroup>
          <TileLayer
            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            maxZoom={20}
            maxNativeZoom={19}
          />

          {gaps.map((gap, index) => (
            <Gap key={index} gap={gap} />
          ))}

          {detections.map((detection, index) => (
            <Detection key={index} detection={detection} />
          ))}

          {detections
            .filter((d) => d.boundGap)
            .map((detection, index) => (
              <Polyline
                key={index}
                positions={[detection.centroid, detection.boundGap.centroid]}
                fillColor="black"
                color="black"
                weight={2}
                pmIgnore={true}
              />
            ))}
        </MapContainer>
      )}
    </div>
  );
};

export default SensorMock;
