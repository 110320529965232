import { CButton, CFormCheck, CFormInput } from "@coreui/react";
import React from "react";
import { Popup } from "react-leaflet";
import "./OverlayPopup.scss";

const OverlayPopup = ({ overlay, onUrlChanged, onSave, onTransparent }) => {
  return (
    <Popup className="overlay-image-url-popup">
      <CFormInput
        type="text"
        placeholder="Overlay image URL"
        value={overlay.imageUrl}
        onChange={(e) => onUrlChanged(e.target.value)}
      />
      <div className="overlay-controls">
        <CButton onClick={onSave} disabled={!overlay.isDirty}>
          Save {overlay.isDirty ? "*" : ""}
        </CButton>
        <CFormCheck
          onClick={onTransparent}
          type="checkbox"
          label="Transparent"
        />
      </div>
    </Popup>
  );
};

export default OverlayPopup;
