import { CButton, CFormInput } from "@coreui/react";
import React, {useMemo } from "react";

import { Popup } from "react-leaflet";
import { useAddDetection, useUpdateDetectionInfo } from "../../contexts/SensorMock/SensorMockContext";
import { gapProperties, getEndValue, vehicleProperties } from "../../contexts/SensorMock/constants";

const InfoPopup = ({ object, isGap = false }) => {

  const addDetection = useAddDetection();
  const updateDetectionInfo = useUpdateDetectionInfo();

  const properties = useMemo(
    () => (isGap ? gapProperties : vehicleProperties),
    [isGap]
  );

  const boundToGap = useMemo(() => !isGap && object.boundGap, [isGap, object]);

  return (
    <Popup className="vehicle-info-popup">
      <div className="vehicle-info-container">
        {Object.keys(properties)
          .filter((key) => key !== "boundGap.gapId" || boundToGap)
          .map((key) => (
            <CFormInput
              key={key}
              disabled={
                isGap ||
                (key === "groupId" && boundToGap) ||
                key === "boundGap.gapId"
              }
              label={properties[key]}
              name={key}
              value={getEndValue(object, key)}
              onChange={e => updateDetectionInfo(object.id, e.target.name, e.target.value)}
            />
          ))}
      </div>
      {isGap ? (
        <div className="popup-actions">
          <CButton
            color="danger"
            onClick={() => addDetection(object.coordinates[0], "free", object.gapId)}
          >
            Free
          </CButton>
          <CButton
            color="danger"
            onClick={() => addDetection(object.coordinates[0], "car", object.gapId)}
          >
            Car
          </CButton>
        </div>
      ) : null}
    </Popup>
  );
};

export default InfoPopup;
